import React from 'react'
import { graphql } from 'gatsby'

const VideoTemplate = ({ data }) => {
  const { markdownRemark: video } = data
  const { frontmatter, html } = video
  return (
    <>
      <div>{frontmatter.id}</div>
      <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
      />
    </>
  )
}

export default VideoTemplate;

export const articleQuery = graphql`
  query VideoByPath($videoPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $videoPath } }) {
      html
      frontmatter {
        id
        title
        subtitle
        date
        tags
        summary
        link
      }
    }
  }
`